import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './pages/App.js';
import Landing from './pages/betalanding.tsx';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-228578715-1";

ReactGA.initialize(TRACKING_ID);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Landing />
  </React.StrictMode>
);

