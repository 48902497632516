import React from 'react';
import './App.css';
import { ReactComponent as Logo } from "../components/logo.svg";

import { initializeApp } from "firebase/app";
import { getFirestore , doc, setDoc} from "firebase/firestore";
import axios from 'axios';
import useAnalyticsEventTracker from '../components/GoogleAnalyticsTracker.tsx';

const firebaseConfig = {
  apiKey: "AIzaSyA7Gpp4cNYAdVZyTf9HQ1hN1dvgXAcn2pA",
  authDomain: "examgoat-5e8fd.firebaseapp.com",
  projectId: "examgoat-5e8fd",
  storageBucket: "examgoat-5e8fd.appspot.com",
  messagingSenderId: "95158587044",
  appId: "1:95158587044:web:1842474918520615022031",
  measurementId: "G-VJR981S693"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

function Landing(){
  return(
    <div className="App">
      <LandingPage />
    </div>

  )
}

function LandingPage() {
  const [formValue, setFormValue] = React.useState('');

  const gaEventTracker = useAnalyticsEventTracker("Beta");

  const validateEmail = (email:string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  

  const SendEmail = async (e:any) => {

      e.preventDefault();
      let ip:string = "";

      if(!validateEmail(formValue)){
        alert("Please enter a valid email address");
        return;
      }
      else{
        gaEventTracker("Sign up")
        await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=10afa8e6114f4a9890acbf41f26b8664')
        .then(response => {
            ip = response.data.ip_address;
        })
        .catch(error => {
            console.log(error);
        });

      await setDoc(doc(db, "earlyAccess",formValue), {
        "email": formValue,
        "ip": ip.toString()
      });
      alert("Thank you for your interest in ExamGoat. We will be in touch soon!");
      setFormValue('');
      }
  }

  return (
    <>
          <div className='absolute h-full w-full bg-activePanelButton'>
            
            <div className='relative top-1/4 flex flex-col justify-center items-center'>
              <Logo className=" h-64 w-64"/>
              <div className='relative text-6xl font-extrabold text-text'>Under Construction</div>
              <div className='relative text-2xl font-bold m-2 text-text'> Enter your email to be notified</div>
                <form onSubmit={(e) => {SendEmail(e)}} className="" >

                  <input className='rounded-l-md text-black p-2 outline outline-2 bg-text' placeholder='Enter Email' value={formValue} onChange={(e) => setFormValue(e.target.value)}></input>
                  <button type="submit" className='rounded-r-md mr-2 p-2  bg-tint h-10 w-auto outline outline-2'>{'>'}</button>

                </form>
              </div>
          </div>
          <div className=''>
            <button className='peer absolute bottom-0 left-0 underline text-text ml-4'> Privacy Policy</button>
            <div className='hidden peer-hover:block h-100 absolute bottom-0 left-0 w-1/2 m-10 p-2 bg-white outline outline-2 whitespace-pre-line text-left'>
              Hi! This is the very simple privacy policy for ExamGoat! 
              By making use of the ExamGoat website, you are consenting to the use of your data, including storage of email addresses, logging of IP addresses and tracking forms submitted, for the purposes of: 
                <ol>
                  <li>a) Sending news emails</li>
                  <li>b) Restriction of access by IP address for harmful users</li>
                  <li>c) Analytics statistics</li>
                  </ol>

              If you'd like to get your data deleted, feel free to email me at Lux@luxetrix.xyz with a deletion request and I will delete your information ASAP.
              <div>If you do not consent to this privacy policy, you can opt out by not using the ExamGoat website.</div>
              <div>IP Logging is handled by https://www.abstractapi.com/, their privacy policy is available on their wesbite. </div>
              </div>
          </div>
    </>
  );
}

export default Landing;